
.price-box {
    width: 100%;
    text-align: center;
    border: 1px solid var(--grey);
    border-radius: 5px;
    background-color: var(--white);
    padding-bottom: 3rem;
    margin: 1rem 1.5rem;
    overflow: hidden;
    z-index: 0;
    box-shadow: var(--box-shadow);

}

.price-box p {
    font-size: 2rem;
    margin-bottom: 1rem;
    text-transform: capitalize;
     color: var(--white); 
}

.price-box h4 {
    font-size: 3rem;
    margin-bottom: 2rem;
     color: var(--white); 
}

.price-box h4 span {
    font-size:1.2rem;
    vertical-align: middle;
    /* color: var(--white);  */
}

.price-box ul {
    list-style: none;
    margin-bottom: 3rem;
}

.price-box ul li {
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--color-text);
    text-transform: capitalize;
    padding: 1.2rem 0;
    border-top: 1px solid var(--grey);
    
} 

.price-box ul li:last-child {
    border-bottom: 1px solid var(--grey);

}

.price-box .btn3 {
    font-size: 1.4rem;
    text-decoration: none;
    background-color: var(--color-secondary);
    padding: 1rem 1.4rem;
    border-radius: 5px;
    margin-bottom: 2rem;
    color: var(--white);
    transition: all 3s;
}

.price-box .btn3:hover {
    font-size: 1.5rem;
}

.price-list {
    padding: 0 1rem;
}

.box3,
.btn3 {
    padding: 1rem 0;
    margin-bottom: 2rem;
    color: var(--white);
    background: linear-gradient(to right 
    bottom, rgba(234, 67, 187, 1), rgba(104, 3, 71, 1));
} 

.price-box:nth-child(even) {
    transform: scale(1.2);
    z-index: 999;
}

/* PRICE BOX ANIMATION */
.cc {
    animation: colorChanger 2s infinite alternate;
}

@keyframes colorChanger {
    0% {
       color: rgba(104, 3, 71, 1);
       transform: translateX(1rem);  
    }
    50% {
        color: var(--white);
        transform: translateX(0);  
     }
     100% {
        color: rgba(104, 3, 71, 1);
        transform: translateX(-1rem);  
     }
}

