// .footer {
//   background: #eb4034;
//     color: #fff;
//     font-size: 1.6rem;
//     display: flex;
//     justify-content: space-around;
//     align-items: center;
//     height: 8rem;
//     p {
//       color: #fff;
//     }
//   }


  /* FOOTER SECTION */
/* FOOTER SECTION */
.footer-section {
  background-color: black;
  padding: 1%;
  width: 100%;
  position: relative;
}

// .top-line {
// width: 100%;
// position: relative;
// }

.footer-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(to right,
  var(--color-secondary)
  var(--color-primary)); 
}

.footer {
  display: grid;
  grid-template-columns: repeat(
      auto-fit, minmax(16rem, 1fr));
      row-gap: 1rem;
}

.footer-links {
  list-style: none;
}

.footer-links li {
  font-size: .8rem;
  color: var(--white);
  text-decoration: none;
  text-transform: capitalize;
  cursor: pointer;
  transition: all 3s;
}

.footer-links li:hover {
  color: var(--color-primary)
}

p {
  font-size: 18px;
}

