

.fixed {
  width: 100%;
  position: fixed;
  top: 0;
  transition: all 0.5s;
  z-index: 9;
}

header {
  width: 100%;
  background: #F8F8FF;
  color: black;

  .header {
    font-size: 20px;
    width: 100%;
    height: 8rem;
    max-width: 1000px;
    margin: 0 auto;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .logo a h2 {
    width: 25%;
    color: #eb4034;
    cursor: pointer;
    span {
      color: #2a8a86;
    }
  }

  nav {
    width: 75%;
    display: flex;
    justify-content: space-between;
    ul {
      display: flex;
      justify-content: space-between;
      list-style: none;

      .logo-mobile {
        display: none;
      }

      li {
        margin: 0 5px;
        a {
          color: #eb4034;
          &:hover {
            color: #000000;
          }
        }
      }
    }
  }

  .header-right {
    display: flex;


    span {
      margin: 0 5px;

      p {
        color: #fff;
      }
    }

    .links a {
      margin: 0 5px;
      color: #eb4034;
      &:hover {
        color: #000000;
      }
      &.active {
        color: #000000;
      }
    }
  }

  .menu-icon {
    cursor: pointer;
    display: none;
  }

  @media screen and (max-width: 800px) {
    nav {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100vh;
      background: #FFFFFF;
      padding: 1rem;
      z-index: 999;
      transform: translateX(-200%);
      transition: all 0.3s;

      .nav-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        transform: translateX();
        transition: all 0.3s;
      }

      .show-nav-wrapper {
        transform: translateX(100%);
      }

      ul {
        display: block;
        .logo-mobile {
          display: flex;
          justify-content: space-between;
          align-items: center;
          > * {
            cursor: pointer;
          }
        }
        li {
          padding: 5px 0;
          border-bottom: 1px solid #333;
          a {
            display: block;
          }
        }
      }

      .header-right {
        display: block;
       
        .links {
          display: block;
          a {
            display: block;
            margin: 0;
            padding: 5px 0;
            border-bottom: 1px solid #333;
          }
        }
      }
    }
 
    .show-nav {
      transform: translateX(0);
    }
    .hide-nav {
      transform: translateX(-200%);
    }
    .menu-icon {
      display: flex;
      > * {
        margin-left: 2rem;
      }
    }
  }
}

.active {
  position: relative;
  color:#000000;
}

.active::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 100%;
  height: 2px;
  background-color: red;
}




