
.grid {
    width: 100%;
    margin: 0 auto;
    padding: 2rem 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25rem,1fr));
    row-gap: 3rem;
    column-gap: 3rem;
  }

.title {
    text-align: center;

}

.s-head {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom:3rem;
    text-transform: capitalize;
    background: linear-gradient(to right,
       var(--color-secondary),
       var(--color-primary));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    position: relative;
}

.s-head::before {
    content: "";
    position: absolute;
    top: 110%;
    left: 50%;
    width: 3.5rem;
    height: 2px;
    background-color: var(--color-secondary);
    transform: translateX(-50%);
}