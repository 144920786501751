// VARIABLES
:root {
  --color-primary: red;
  --color-secondary:blue;
  --color-text:#6d7c90;
  --white: #fff;
  --grey:#eee;
  --light-grey:#f3f4f6;
  --dark: #333;
  --brown:rgba(104, 50, 5, 1);
  --purple: rgba(80, 3, 71, 1);
  --box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, .5)
}

/* GLOBAL STYLES */
*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-family: "Public Sans", sans-serif;
}

body {
  width: 100%;
}

p {
  font-size: 1rem;
  font-weight: 400;
  color: var(--color-text);
  line-height: 1.5;
}

.text-white {
  color: #eee;
}

section {
  padding: 5rem 0;
}

.container {
  width: 100%;
  max-width: 100rem;
  margin: 0 auto;
  padding: 0 2.5rem;
}

/* UTILITY CLASSES */
.u-mb-small {
  margin-bottom: 1.5rem;
  font-size: 3rem;
}

.u-mb-medium {
  margin-bottom:3rem;
  color: var(--grey);
  font-size: 1.5rem;
}

.u-mb-large {
  margin-bottom: 5rem;
}

.btn {
  font-size: 1rem;
  text-transform: uppercase;
  text-decoration: none;
  padding: .8rem;
  background-color: blue;
  color: var(--white);
  border: 1px solid red;
  border-radius: 5px;
  border-width: 0 0 0 4px;
  transition: all 3s;
}

.btn:hover {
  background-color: red;
  border: 1px solid blue;
  border-width: 0 0 0 4px;
  font-size: 1.7rem;

}


/* INTRO SECTION */
/* INTRO SECTION */
/* INTRO SECTION */
.intro-section {
  position: relative;
  width: 100%;
  height: 90vh;
  margin: 0 auto;
  text-align: left;
  overflow: hidden; 
  clip-path: polygon(0 0, 
  100% 0, 100% 70vh, 0% 100%);
}

.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: rgba(0, 0, 0, .7);
}

.intro-content {
  padding: .5rem 2.5rem;
  z-index: 1;
}

.intro-text {
  width: 65%;
}

.intro-text h1 {
  display: block;
  font-size: 3rem;
  font-weight: 700;
  color: var(--white);
}

.first-span {
  font-size: 3rem;
  display: block;
  text-transform: capitalize;
  color: var(--grey);
  opacity: .7;
}

.second-span::before {
  content: "ANIMATION";
  animation: text-changer 8s
  infinite 2s;
}

@keyframes text-changer {
  0% {
      content: "FAMIWEB";
      color: var(--color-primary);
  }

  25% {
      content: "YOOF";
      color: rgba(255, 255, 255, .7);
  }

  50% {
      content: "FAMIWEB";
      color: var(--color-secondary);
  }

  75% {
      content: "YOOF";
      color: rgba(255, 255, 255, .7);
  }

  100% {
      content: " BUSINESS WEBSITE";
      color: var(--color-primary);
  }
}



/* ABOUT SECTION  */
/* ABOUT SECTION  */
/* ABOUT SECTION  */
.title {
  text-align: center;

}

.s-head {
  font-size: 2rem;
  font-weight: 700;
  text-transform: capitalize;
  background: linear-gradient(to right,
     var(--color-secondary),
     var(--color-primary));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  position: relative;
}

.s-head::before {
  content: "";
  position: absolute;
  top: 110%;
  left: 50%;
  width: 3.5rem;
  height: 2px;
  background-color: var(--color-secondary);
  transform: translateX(-50%);
}

/* CTA */
.cta {
  width: 50%;
  margin: 0 auto;
  text-align: center;
}

.embed {
  width: 85%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  background: linear-gradient(90deg,
  rgba(219, 100, 0, .5) 0%,
  rgba(80, 3, 71, .5) 50%,
  transparent 50%), 
  url(https://w7.pngwing.com/pngs/66/677/png-transparent-simple-abstract-lines-background-lines-black-green-and-blue-abstract-painting-texture-angle-triangle-thumbnail.png);
  background-position: center;
  background-size: cover;
  padding: 2rem 0;
  border-radius: 5px;
}


.top-line {
width: 100%;
position: relative;
}

.top-line::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(to right,
  var(--color-secondary)
  var(--color-primary)); 
}

/* SERVICE SECTION */
.product-section {
  background-color: var(--light-grey);
}

.container {
  width: 100%;
  max-width: 100rem;
  margin: 0 auto;
  padding: 0 2.5rem;
}

/* PRICING SECTION */
/* PRICING SECTION */
.price {
  width: 100%;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: stretch;

}

.title {
  text-align: center;

}

/* TABLETS */
@media screen and (max-width: 769px) {

  /* PRICING SECTION */
  .price {
      width: 80%;
      flex-direction: column;
  }
  .price-box:nth-child(even) {
      transform: scale(1);
      z-index: 999;
  }

}

/* MEDIA QUERY */
/* MEDIA QUERY */
/* MEDIA QUERY */


/* MOBILE QUERIES*/
@media screen and (max-width: 600px) {
  /* HEADER SECTION */



/* .check {
  display: inline-block;
} */

/* INTRO SECTION */
.intro-content {
  padding: 7rem 2.5rem;
} 

.intro-content h1 {
  font-size: 2.5rem;
}

.intro-text{
  width: 100%;
  text-align: center;

}

.first-span {
  font-size: 2rem;
}

.intro-text p {
  text-align: justify;
  text-justify: inter-word;
}

.cta {
  width: 80%;
}
}



