


section {
    padding: 5rem 0;
}


.container {
    width: 100%;
    max-width: 100rem;
    margin: 0 auto;
    padding: 0 2.5rem;
}
.u-mb-medium {
    margin-bottom:3rem;
}

/* ABOUT SECTION  */
.title {
    text-align: center;

}

.s-head {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom:3rem;
    text-transform: capitalize;
    background: linear-gradient(to right,
       var(--color-secondary),
       var(--color-primary));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    position: relative;
}

.s-head::before {
    content: "";
    position: absolute;
    top: 110%;
    left: 50%;
    width: 3.5rem;
    height: 2px;
    background-color: var(--color-secondary);
    transform: translateX(-50%);
}

.about{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.about-text {
    width: 50%;
    margin-right: 8px;
}

.about img {
    width: 90%;
    border-radius: 7px;
}


/* MEDIA QUERY */

/* TABLETS */
@media screen and (max-width: 769px) {

    /* ABOUT SECTION */
    .about {
        flex-direction: column-reverse;
    }

    .about-text {
        width: 100%;
        text-align: center;
    }

    .about img {
        width: 100%;
        border-radius: 7px;
    }
}
