


section {
    padding: 5rem 0;
}


.container {
    width: 100%;
    max-width: 100rem;
    margin: 0 auto;
    padding: 0 2.5rem;
}
.u-mb-medium {
    margin-bottom:3rem;
}

/* SERVICE SECTION */

.product {
    width: 100%;
    margin: 0 auto;
    padding: 2rem 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25rem,1fr));
    row-gap: 3rem;
    column-gap: 3rem;
}

.container {
    width: 100%;
    max-width: 100rem;
    margin: 0 auto;
    padding: 0 2.5rem;
}


.product-box {
    text-align: center;
    border: 1px solid transparent;
    border-bottom: 3px solid var(--purple);
    border-radius: 5%;
    padding: 1.5rem;
    background-color: var(--white);
    cursor: pointer;
    box-shadow: var(--box-shadow);
    transform: translateY();
    position: relative;
    overflow: hidden; 
    transition: all .3s;
}

.product-box:hover {
    border: 1px solid var(--color-text);
    background-color: var(--grey);
    transform: translateY(-2rem);
    box-shadow: var(--box-shadow);
}

.product-box::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 4px;
    background-color: var(--color-text);
    transform: translateX(-100%);
    transform-origin: left;
    transition: all .5s;

} 

.product-box:hover::before {
    transform: translateX(0);
}

.product-box i {
    font-size: 2rem;
    color: var(--color-primary);
    margin-bottom: 1rem;
}

.product-box:nth-child(even) i {
    color: var(--color-secondary);
}

.t-head {
    font-size: 2rem;
    font-weight: 400;
    color: var(--dark);
    text-transform: capitalize;
    margin-bottom: 2rem;
}

.product-box p {
    font-size: 1.2rem;
    text-align: justify;
}



// FamiWeb CSS
// FamiWeb CSS
// .grid {
//     width: 24rem;
//     margin: 5px;
//     height: 36rem;
//     position: relative;

//     .content {
//       text-align: center;
//       position: absolute;
//       bottom: 0;
//       left: 0;
//       width: 100%;
  
//       .details {
//         // display: flex;
//         // justify-content: space-between;
//         // align-items: center;
//         padding: 0 1rem;
//         h4 {
//           font-weight: 400;
//           font-size: 1.8rem;
//         }
//         p {
//           font-weight: 500;
//           color: orangered;
//         }
//       }
//     }
//   }

  @media screen and (max-width: 700px) {
    .grid {
    width: 100%;
    height: 100%;
    }
  }